import axios from 'axios';

export type HasAccessResponse = {
    hasAccess: boolean;
    stateToken: string | null;
}

export async function checkWaveAccess(): Promise<HasAccessResponse> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/wave/api-key`
        );
        
        return response.data as HasAccessResponse;
    } catch (error: any) {
        console.log("Request to /wave/api-key failed: ", error.message)
        return {
            'hasAccess': false,
            'stateToken': null
        }
    }
}

export async function checkMondayAccess(): Promise<HasAccessResponse> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/monday/api-key`
        );
        
        return response.data as HasAccessResponse;
    } catch (error: any) {
        console.log("Request to /monday/api-key failed: ", error.message)
        return {
            'hasAccess': false,
            'stateToken': null
        }
    }
}