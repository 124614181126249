import React, { useCallback } from "react";
import { useAuth } from "../auth/auth-context";
import { Button } from 'monday-ui-react-core';
import { useUserInfo } from "../user/user-info-context";

enum LoginType {
    MONDAY, 
    WAVE
}   

function getWaveAppsRedirectUri(stateToken: string): string {
    const clientId = process.env.REACT_APP_WAVE_CLIENT_ID;
    const responseType = process.env.REACT_APP_WAVE_RESPONSE_TYPE;
    const scopes = process.env.REACT_APP_WAVE_SCOPES;
    const redirectCallbackUri = process.env.REACT_APP_WAVE_REDIRECT_CALLBACK_URI;
  
    return `https://api.waveapps.com/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&scope=${scopes}&redirect_uri=${redirectCallbackUri}&state=${stateToken}`
}

function getMondayRedirectUri(accountSlug: string, stateToken: string): string {
    const clientId = process.env.REACT_APP_MONDAY_CLIENT_ID;
    const redirectCallbackUri = process.env.REACT_APP_MONDAY_REDIRECT_CALLBACK_URI;

    return `https://${accountSlug}.monday.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectCallbackUri}&state=${stateToken}`
}

const OAUTH_WINDOW_FEATURES = "width=1000,height=800,resizable=yes,scrollbars=yes,status=yes";

export function SettingsScreen() {
    const { hasMondayAccess, hasWaveAccess, mondayStateToken, waveStateToken, refreshMondayAccess, refreshWaveAccess } = useAuth();
    const { accountSlug } = useUserInfo();

    const handleMondayLogin = useCallback(() => {
        if (!mondayStateToken || !accountSlug) {
            alert(`mondayStateToken = ${mondayStateToken}, accountSlug = ${accountSlug}. Please refresh the page and try again. Both should be non-null`);   
        }

        const mondayOauthUrl = getMondayRedirectUri(accountSlug as string, mondayStateToken as string);
        console.log("Opening Monday OAuth URL: ", mondayOauthUrl);
        const authWindow: Window | null = window.open(mondayOauthUrl, '_blank', OAUTH_WINDOW_FEATURES);

        // Polling to check if the popup was closed, but only if authWindow is not null
        if (authWindow) {
            const pollTimer = setInterval(() => {
                if (authWindow.closed) {
                    clearInterval(pollTimer);
                    refreshMondayAccess();
                }
            }, 1000);
        } else {
            alert("Failed to open Monday OAuth URL. Please give access to open a popup window.");
        }
    }, [mondayStateToken, accountSlug, refreshMondayAccess]);

    const handleWaveLogin = useCallback(() => {
        if (!waveStateToken) {
            alert("Wave State Token is not available. Please refresh the page and try again.");   
        }

        console.log("waveStateToken = ", waveStateToken);

        const waveOauthUrl = getWaveAppsRedirectUri(waveStateToken as string);
        console.log("Opening Wave OAuth URL: ", waveOauthUrl);
        const authWindow: Window | null = window.open(waveOauthUrl, '_blank', OAUTH_WINDOW_FEATURES);

        // Polling to check if the popup was closed, but only if authWindow is not null
        if (authWindow) {
            const pollTimer = setInterval(() => {
                if (authWindow.closed) {
                    clearInterval(pollTimer);
                    refreshWaveAccess();
                }
            }, 1000);
        } else {
            alert("Failed to open wave OAuth URL. Please give access to open a popup window.");
        }
    }, [waveStateToken, refreshWaveAccess]);

    const handleLogin = useCallback((type: LoginType) => {
        if (type === LoginType.MONDAY) {
            handleMondayLogin();
        } else if (type === LoginType.WAVE) {
            handleWaveLogin();
        }
    }, [handleMondayLogin, handleWaveLogin]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100vh', paddingLeft: '10px' }}>
            <div style={{ marginBottom: '20px' }}>
                <div>
                    Monday.com connection status: <span style={{ color: hasMondayAccess ? 'green' : 'red' }}>{hasMondayAccess ? 'Connected' : 'Disconnected'}</span>
                    {!hasMondayAccess && <Button onClick={() => handleLogin(LoginType.MONDAY)} style={{ marginLeft: '10px' }} disabled={hasMondayAccess}>Connect with Monday</Button>}
                </div>
            </div>
            <div>
                <div>
                    Wave connection status: <span style={{ color: hasWaveAccess ? 'green' : 'red' }}>{hasWaveAccess ? 'Connected' : 'Disconnected'}</span>
                    {!hasWaveAccess && <Button onClick={() => handleLogin(LoginType.WAVE)} style={{ marginLeft: '10px' }} disabled={hasWaveAccess}>Connect with Wave</Button>}
                </div>
            </div>
        </div>
    );
}