import React, { useContext, createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import mondaySdk from 'monday-sdk-js';
import { HasAccessResponse, checkMondayAccess, checkWaveAccess } from "../repository/auth-repository";
import axios from 'axios';

const monday = mondaySdk();

interface AuthContextType {
  hasMondayAccess: boolean;
  hasWaveAccess: boolean;
  mondayStateToken: string | null;
  waveStateToken: string | null;
  refreshMondayAccess: () => Promise<void>;
  refreshWaveAccess: () => Promise<void>; 
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [sessionToken, setSessionToken] = useState<string | null>(null);
    const [hasWaveAccess, setWaveAccess] = useState<boolean>(false);
    const [hasMondayAccess, setMondayAccess] = useState<boolean>(false);
    const [mondayStateToken, setMondayStateToken] = useState<string | null>(null);
    const [waveStateToken, setWaveStateToken] = useState<string| null>(null);

    // Used to attach an axios interceptor so that all requests have the session token
    const [isAxiosInterceptorReady, setAxiosInterceptorReady] = useState<boolean>(false);

    const getSessionToken = useCallback(async () => {
        try {

            monday.listen("context", async (res: any) => {
                console.log("Context received: ", res);
            });


            const sessionTokenResponse = await monday.get("sessionToken");
            console.log("Session token received: ", sessionTokenResponse.data);
            setSessionToken(sessionTokenResponse.data);
        } catch (error) {
            console.log("No session token available. Probably will need to refresh")
        }
    }, []);

    // Get the session token to attach to interceptor in axios
    useEffect(() => {
        getSessionToken();
    }, [getSessionToken]);

    // When session token received from monday, attach it to axios interceptor
    useEffect(() => {
        let axiosInterceptor: number | null = null; 
        if (sessionToken) {
            axiosInterceptor = axios.interceptors.request.use(config => {
                config.headers.Authorization = `Bearer ${sessionToken}`;
                return config;
            }, error => {
                return Promise.reject(error);
            });
            setAxiosInterceptorReady(true);
            console.log("Axios interceptor is ready.")
        }

        return () => {  
            setAxiosInterceptorReady(false);
            if (axiosInterceptor) {
                axios.interceptors.request.eject(axiosInterceptor);
            }
        };
    }, [sessionToken]);

    const verifyMondayAccess = useCallback(async () => {
        if (!isAxiosInterceptorReady) { 
            setMondayAccess(false);
            setMondayStateToken(null);
            return 
        }
        let response: HasAccessResponse | null = null;
        try {
            response = await checkMondayAccess();

            console.log("Inside verifyMondayAccess: response = ", response);

            if (response.hasAccess) {
                setMondayAccess(response.hasAccess);
                setMondayStateToken(null);
            } else if (response.stateToken === null) {
                setMondayAccess(false);
                setMondayStateToken(null);
            } else {
                setMondayAccess(false);
                setMondayStateToken(response.stateToken);
            }
        } catch (error) {
            console.log("verifyMondayAccess: Error checking monday access:")
            setMondayAccess(false);
            setMondayStateToken(null);
        }
    }, [isAxiosInterceptorReady]);

    useEffect(() => {
        verifyMondayAccess();
    }, [verifyMondayAccess]);

    const verifyWaveAccess = useCallback(async () => {
        if (!isAxiosInterceptorReady) {
            setWaveAccess(false);
            setWaveStateToken(null);
            return;
        }

        let response: HasAccessResponse | null = null;
        try {
            response = await checkWaveAccess();
            if (response.hasAccess) {
                setWaveAccess(response.hasAccess);
                setWaveStateToken(null);
            } else if (response.stateToken === null) {
                setWaveAccess(false);
                setWaveStateToken(null);
            } else {
                setWaveAccess(false);
                setWaveStateToken(response.stateToken);
            }
        } catch (error) {
            setWaveAccess(false);
            setWaveStateToken(null);
        } finally {
            console.log("verifyWaveAccess: response = ", response);
        }
    }, [isAxiosInterceptorReady]); // Need to check for monday access because wave api key is stored in monday

    useEffect(() => {
        verifyWaveAccess();
    }, [verifyWaveAccess]);

    const refreshMondayAccess = useCallback(async () => {
        await verifyMondayAccess();
    }, [verifyMondayAccess]);

    const refreshWaveAccess = useCallback(async () => {
        await verifyWaveAccess();
    }, [verifyWaveAccess]);

    return (
        <AuthContext.Provider value={{ hasMondayAccess, hasWaveAccess, mondayStateToken, waveStateToken, refreshMondayAccess, refreshWaveAccess }}>
            {children}
        </AuthContext.Provider>
    );
};