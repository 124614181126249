import React, { useEffect } from 'react';
import { AuthProvider } from './auth/auth-context';
import { UserInfoProvider } from './user/user-info-context';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { SettingsScreen } from './ui/settings-screen';
import './App.css';

function App() {
  useEffect(() => {
    // Don't need to do anything here
  }, []);

  return (
    <Router>
      <AuthProvider>
        <UserInfoProvider>
          <div className="App">
            <Routes>
              <Route path="/settings" element={<SettingsScreen />}></Route>

              {/* These 2 can't be authenticated because the window where the user giving access doesn't have the Authorization Token */}
              <Route path="/wave/login-successful" element={<div>Please close this window and proceed in monday.com</div>} />
              <Route path="/monday/login-successful" element={<div>Please close this window and proceed in monday.com</div>} />

              {/* On login failed request the user to try it again */}
              <Route path="/wave/login-failed" element={<div>Some went wrong. Please close the window and try again.</div>} />
              <Route path="/monday/login-failed" element={<div>Some went wrong. Please close the window and try again.</div>} />

              <Route path="/" element={<Navigate to="/settings" replace={true} />} />
            </Routes>
          </div>
          </UserInfoProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
