import React, { useContext, createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import mondaySdk from 'monday-sdk-js';

const monday = mondaySdk();

interface UserContextType {
  accountSlug: string | null;
}

const UserInfoContext = createContext<UserContextType | undefined>(undefined);

export const useUserInfo = (): UserContextType => {
  const context = useContext(UserInfoContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

interface UserInfoProviderProps {
  children: ReactNode;
}

export const UserInfoProvider = ({ children }: UserInfoProviderProps): JSX.Element => {
  const [accountSlug, setAccountSlug] = useState<string | null>(null);

  const getAccountSlug = useCallback(async () => {
    try {
      const query = `query {
        me {
          account {
            slug
          }
        }
      }`

      const accountSlugResponse = await monday.api(query);
      setAccountSlug(accountSlugResponse.data.me.account.slug);
    } catch (error) {
      console.log("No account slug available. Probably will need to refresh the monday.com page")
    }
  }, []);

  useEffect(() => {
    getAccountSlug();
  }, [getAccountSlug]);

  return (
    <UserInfoContext.Provider value={{ accountSlug }}>
      {children}
    </UserInfoContext.Provider>
  );
};